export interface ContactUsInputValidationModel {
  key: ValidationPatternType;
  errorMessage: string;
}

export enum ValidationPatternType {
  Pattern = 'pattern',
  MaxLength = 'maxLength',
  Required = 'required',
}
