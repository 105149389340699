@if (showContactUsForm()) {
  <p-toast key="bottomCenter" position="bottom-center"></p-toast>
  <div class="flex flex-column contact-us-container mx-auto">
    <p-dialog [header]="dialogHeader" [closable]="false" [modal]="isDialogModal()" [visible]="isLoadingDialogVisible()">
      <p-header>
        <div class="dialog-header">{{ dialogHeader }}</div>
        <div class="flex">
          <div class="flex-initial flex align-items-center justify-content-center">
            <p-progressSpinner styleClass="w-2rem h-2rem" />
          </div>
          <div class="dialog-content-div">
            <p class="flex-column flex align-self-stretch dialog-content-text">{{ dialogContent }}</p>
          </div>
        </div>
      </p-header>
    </p-dialog>

    <div class="header flex flex-column">
      <h1 class="title flex flex-row">{{ titleText() }}</h1>
      <ppg-resource-item class="subtitle" [resourceKey]="resourceItem.LEAD_GEN_SUB_HEADING" />
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="checkForm()" class="flex flex-column contact-us-form">
      <div class="flex">
        <div class="flex-1 flex justify-content-start align-items-start input-lg-flex-row">
          @for (data of firstAndLastNameInputDatas; track $index) {
            <ppg-contact-us-form-input
              class="w-full"
              [formControl]="getFormControlByName(data.name)"
              [inputId]="data.name"
              [labelText]="data.label"
              [inputPlaceholderText]="data.placeholder"
              [inputType]="ContactUsFormDisplayOption.REQUIRED"
              [validationModel]="data.validationModels"
              [showErrors]="showErrorsInComponent">
            </ppg-contact-us-form-input>
          }
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 flex justify-content-start align-items-start input-lg-flex-row">
          @for (data of emailAndPhoneNumberInputDatas; track $index) {
            <ppg-contact-us-form-input
              class="w-full"
              [formControl]="getFormControlByName(data.name)"
              [inputId]="data.name"
              [labelText]="data.label"
              [inputPlaceholderText]="data.placeholder"
              [inputType]="ContactUsFormDisplayOption.REQUIRED"
              [validationModel]="data.validationModels"
              [showErrors]="showErrorsInComponent">
            </ppg-contact-us-form-input>
          }
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 flex justify-content-start align-items-start input-lg-flex-row">
          <ppg-contact-us-form-input
            class="w-full"
            [formControl]="getFormControlByName('companyName')"
            [inputId]="'companyName'"
            [labelText]="leadGeneration()?.content?.companyNameLabel ?? ''"
            [inputPlaceholderText]="leadGeneration()?.content?.companyNamePlaceholder ?? ''"
            [inputType]="ContactUsFormDisplayOption.REQUIRED"
            [validationModel]="getCompanyNameValidationModels(leadGeneration())"
            [showErrors]="showErrorsInComponent">
          </ppg-contact-us-form-input>
          <ppg-contact-us-form-dropdown
            class="w-full"
            [formControl]="getFormControlByName('region')"
            [inputId]="'region'"
            [labelText]="leadGeneration()?.content?.regionLabel ?? ''"
            [inputPlaceholderText]="leadGeneration()?.content?.regionPlaceholder ?? ''"
            [inputOptions]="leadGeneration()?.content?.regionList ?? []"
            [inputType]="ContactUsFormDisplayOption.REQUIRED"
            [invalidFieldMessage]="leadGeneration()?.content?.regionRequiredMessage"
            [showErrors]="showErrorsInComponent">
          </ppg-contact-us-form-dropdown>
        </div>
      </div>
      @if (zipCodeDisplay()) {
        <div class="flex">
          <div class="flex-1 flex justify-content-start align-items-start flex-column input-lg-flex-row">
            <ppg-contact-us-form-input
              class="w-full lg:w-6"
              [formControl]="getFormControlByName('zipcode')"
              [inputId]="'zipcode'"
              [labelText]="leadGeneration()?.content?.zipcodeLabel ?? ''"
              [inputPlaceholderText]="leadGeneration()?.content?.zipcodePlaceholder ?? ''"
              [inputType]="ContactUsFormDisplayOption.OPTIONAL"
              [showErrors]="showErrorsInComponent">
            </ppg-contact-us-form-input>
          </div>
        </div>
      }
      <div class="line-container">
        <div class="line-divider"></div>
      </div>

      @if (question1Display()) {
        <div class="flex">
          <div class="flex-1 flex justify-content-start align-items-start flex-column input-lg-flex-row">
            <ppg-contact-us-form-dropdown
              class="w-full lg:w-6"
              [formControl]="getFormControlByName('question1')"
              [inputId]="'question1'"
              [labelText]="leadGeneration()?.content?.question1Label ?? ''"
              [inputPlaceholderText]="leadGeneration()?.content?.question1Placeholder ?? ''"
              [inputOptions]="leadGeneration()?.content?.question1Options ?? []"
              [inputType]="ContactUsFormDisplayOption.REQUIRED"
              [toolTip]="leadGeneration()?.content?.question1Tooltip"
              [invalidFieldMessage]="leadGeneration()?.content?.question1RequiredMessage"
              [showErrors]="showErrorsInComponent">
            </ppg-contact-us-form-dropdown>
          </div>
        </div>
      }

      <div class="flex flex-1 flex-column input-textarea justify-content-start align-items-start gap-0">
        <label class="font-normal label label__name" htmlFor="comments">
          {{ leadGeneration()?.content?.commentsLabel }}
        </label>
        <textarea
          pInputTextarea
          class="w-full input-textarea-border bg-white"
          id="comments"
          placeholder="{{ leadGeneration()?.content?.commentsPlaceholder }}"
          formControlName="comments"></textarea>
      </div>

      <div class="flex email-subscribe justify-content-start align-items-start">
        <p-checkbox formControlName="emailSubscribe" [binary]="true" inputId="emailSubscribe"> </p-checkbox>
        <div class="flex-element font-normal label label__name">
          {{ leadGeneration()?.content?.emailSubscribeText }}
        </div>
      </div>

      <div class="flex button-border">
        <p-button type="submit" [label]="ppgSubmitButtonText()" class="flex flex-1 button-md-flex" />
      </div>
    </form>
  </div>
}
