import { ChangeDetectionStrategy, Component, forwardRef, input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { ContactUsFormDisplayOption } from '@ppg/core/enums';

import { ContactUsInputValidationModel } from '../../../../data/models/contact-us-input-validation.model';
import { ContactUsFormBase } from '../contact-us-form-base/contact-us-form-base';
import { ContactUsFormControlValueAccessor } from '../contact-us-form-control-value-accessor/contact-us-form-control-value-accessor';
import { ContactUsFormLabelComponent } from '../contact-us-form-label/contact-us-form-label.component';

@Component({
  selector: 'ppg-contact-us-form-input',
  standalone: true,
  imports: [ReactiveFormsModule, ContactUsFormLabelComponent],
  templateUrl: './contact-us-form-input.component.html',
  styleUrls: ['./contact-us-form-input.component.scss', '../../contact-us-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactUsFormInputComponent),
      multi: true,
    },
  ],
})
export class ContactUsFormInputComponent extends ContactUsFormControlValueAccessor implements ContactUsFormBase {
  readonly inputId = input.required<string>();
  readonly labelText = input.required<string>();
  readonly inputPlaceholderText = input.required<string>();
  readonly inputType = input.required<ContactUsFormDisplayOption>();
  readonly formControlName = input<string>();
  readonly formControl = input.required<FormControl>();
  readonly invalidFieldMessage = input<string>();
  readonly ContactUsFormDisplayOption = ContactUsFormDisplayOption;
  readonly validationModel = input<ContactUsInputValidationModel[]>();
  readonly showErrors = input.required<boolean>();

  getErrorMessage(name: string): string {
    return this.validationModel()?.find((t) => t.key === name)?.errorMessage ?? '';
  }
}
