import { ChangeDetectionStrategy, Component, forwardRef, input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { ContactUsFormDisplayOption } from '@ppg/core/enums';

import { ContactUsInputValidationModel } from '../../../../data/models/contact-us-input-validation.model';
import { ContactUsFormBase } from '../contact-us-form-base/contact-us-form-base';
import { ContactUsFormControlValueAccessor } from '../contact-us-form-control-value-accessor/contact-us-form-control-value-accessor';
import { ContactUsFormLabelComponent } from '../contact-us-form-label/contact-us-form-label.component';

@Component({
  selector: 'ppg-contact-us-form-dropdown',
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule, ContactUsFormLabelComponent],
  templateUrl: './contact-us-form-dropdown.component.html',
  styleUrls: ['../../contact-us-form.component.scss', './contact-us-form-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactUsFormDropdownComponent),
      multi: true,
    },
  ],
})
export class ContactUsFormDropdownComponent extends ContactUsFormControlValueAccessor implements ContactUsFormBase {
  readonly inputId = input.required<string>();
  readonly labelText = input.required<string>();
  readonly inputPlaceholderText = input.required<string>();
  readonly inputOptions = input.required<string[]>();
  readonly toolTip = input<string>();
  readonly inputType = input.required<ContactUsFormDisplayOption>();
  readonly invalidFieldMessage = input<string>();
  readonly ContactUsFormDisplayOption = ContactUsFormDisplayOption;
  readonly formControl = input.required<FormControl>();
  readonly validationModel = input<ContactUsInputValidationModel>();
  readonly showErrors = input.required<boolean>();
}
