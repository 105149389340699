import { HttpErrorResponse } from '@angular/common/http';
import { WritableSignal, signal } from '@angular/core';
import { catchError, finalize, map, Observable, throwError } from 'rxjs';

import { ContentComponentType } from '@ppg/core/enums';
import { LeadGenerationComponent } from '@ppg/core/models';

import { ContactUsFormSubmitData } from '../../data/models/contact-us-form-submit-data.model';
import { ContactUsGetFormDataInputModel } from '../../data/models/contact-us-get-form-data-input.model';
import { ContactUsFormRepositoryAbstract } from '../repository/contact-us-form-repository.abstract';

export const DEFAULT_CONTACT_US_FORM_DATA_STATE: LeadGenerationComponent = {
  type: ContentComponentType.LEAD_GENERATION,
  content: {
    formTitle: '',
    formRecipient: '',
    notification: false,
    notificationName: '',
    notificationSubjectLine: '',
    notificationReplyToAddress: '',
    firstNameLabel: '',
    firstNamePlaceholder: '',
    firstNameRequiredMessage: '',
    firstNameMaxLengthMessage: '',
    firstNameInvalidMessage: '',
    lastNameLabel: '',
    lastNamePlaceholder: '',
    lastNameRequiredMessage: '',
    lastNameMaxLengthMessage: '',
    lastNameInvalidMessage: '',
    emailAddressLabel: '',
    emailAddressPlaceholder: '',
    emailAddressRequiredMessage: '',
    emailAddressMaxLengthMessage: '',
    emailAddressInvalidMessage: '',
    phoneNumberLabel: '',
    phoneNumberPlaceholder: '',
    phoneNumberRequiredMessage: '',
    phoneNumberMaxLengthMessage: '',
    phoneNumberInvalidMessage: '',
    companyNameLabel: '',
    companyNamePlaceholder: '',
    companyNameRequiredMessage: '',
    companyNameMaxLengthMessage: '',
    zipcodeLabel: '',
    zipcodePlaceholder: '',
    regionLabel: '',
    regionList: [],
    regionRequiredMessage: '',
    regionPlaceholder: '',
    question1Label: '',
    question1Tooltip: '',
    question1Placeholder: '',
    question1Options: [],
    question1RequiredMessage: '',
    question2Label: '',
    question2Tooltip: '',
    question2Options: [],
    question2Placeholder: '',
    question2RequiredMessage: '',
    commentsLabel: '',
    commentsPlaceholder: '',
    emailSubscribeText: '',
  },
};

export class ContactUsFormUseCases {
  isLoading: WritableSignal<boolean> = signal(false);
  leadGenerationComponent: WritableSignal<LeadGenerationComponent> = signal({ ...DEFAULT_CONTACT_US_FORM_DATA_STATE });

  constructor(private contactUsFormRepository: ContactUsFormRepositoryAbstract) {}

  getContactUsFormData(params: ContactUsGetFormDataInputModel): Observable<LeadGenerationComponent> {
    this.setLoading(true);
    return this.contactUsFormRepository.getContactUsFormData(params).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      }),
      finalize(() => this.setLoading(false)),
    );
  }

  submitContactUsFormData(dataForSubmit: ContactUsFormSubmitData): Observable<boolean> {
    this.setLoading(true);
    return this.contactUsFormRepository.submitContactUsFormData(dataForSubmit).pipe(
      map((response) => {
        return response.ok;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      }),
      finalize(() => this.setLoading(false)),
    );
  }

  setLoading(isLoading: boolean): void {
    this.isLoading.set(isLoading);
  }
}
