import { inject, Injectable } from '@angular/core';

import { CdeGtmService } from '@ppg/features/gtm';

import { GtmEventAction } from '../../enums/gtm-event-action.enum';
import { GtmEventCategory } from '../../enums/gtm-event-category.enum';
import { GtmEventContactBu } from '../../enums/gtm-event-contact-bu.enum';
import { GtmEventLabel } from '../../enums/gtm-event-label.enum';
import { GtmEvent } from '../../enums/gtm-event.enum';

@Injectable({
  providedIn: 'root',
})
export class ContactUsSentGtmService {
  #cdeGtmService = inject(CdeGtmService);

  sendContactUsSecondaryLandingPageOem(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.AutomotiveOemCoatings,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.AutomotiveOemCoatings,
    });
  }

  sendContactUsSecondaryLandingPageRefinish(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.Refinish,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.Refinish,
    });
  }

  sendContactUsSecondaryLandingPageIndustrial(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.IndustrialCoatings,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.IndustrialCoatings,
    });
  }

  sendContactUsSecondaryLandingPagePackaging(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.PackagingCoatings,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.PackagingCoatings,
    });
  }

  sendContactUsSecondaryLandingPageProtective(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.ProtectiveAndMarineCoatings,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.ProtectiveAndMarineCoatings,
    });
  }

  sendContactUsSecondaryLandingPageTraffic(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.TrafficSolutions,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.TrafficSolutions,
    });
  }

  sendContactUsSecondaryLandingPageSilica(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.SilicaProducts,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.SilicaProducts,
    });
  }

  sendContactUsSecondaryLandingPageOptical(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.OpticalMonomersAndCoatings,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.OpticalMonomersAndCoatings,
    });
  }

  sendContactUsSecondaryLandingPageTeslin(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.PpgTeslin,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.PpgTeslin,
    });
  }

  sendContactUsSecondaryLandingPageVendor(region: string, reason: string, emailOptIn: string) {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsSubmit,
      contactBU: GtmEventContactBu.VendorAndSupplier,
      contactReason: reason,
      contactRegion: region,
      contactEmailOptIn: emailOptIn,
      eventAction: GtmEventAction.contactUsSubmitted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.VendorAndSupplier,
    });
  }

  sendGtmEventForBu(bu: string | null | undefined, region: string, reason: string, emailOptIn: boolean): void {
    const emailOptInValue = emailOptIn ? 'Checked' : 'Unchecked';

    switch (bu) {
      case 'oem':
        this.sendContactUsSecondaryLandingPageOem(region, reason, emailOptInValue);
        break;
      case 'refinish':
        this.sendContactUsSecondaryLandingPageRefinish(region, reason, emailOptInValue);
        break;
      case 'industrial':
        this.sendContactUsSecondaryLandingPageIndustrial(region, reason, emailOptInValue);
        break;
      case 'packaging':
        this.sendContactUsSecondaryLandingPagePackaging(region, reason, emailOptInValue);
        break;
      case 'protective-marine':
        this.sendContactUsSecondaryLandingPageProtective(region, reason, emailOptInValue);
        break;
      case 'traffic-solutions':
        this.sendContactUsSecondaryLandingPageTraffic(region, reason, emailOptInValue);
        break;
      case 'silica':
        this.sendContactUsSecondaryLandingPageSilica(region, reason, emailOptInValue);
        break;
      case 'optical':
        this.sendContactUsSecondaryLandingPageOptical(region, reason, emailOptInValue);
        break;
      case 'teslin':
        this.sendContactUsSecondaryLandingPageTeslin(region, reason, emailOptInValue);
        break;
      case 'vendor_supplier':
        this.sendContactUsSecondaryLandingPageVendor(region, reason, emailOptInValue);
        break;
      default:
        break;
    }
  }
}
