import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OCP_APIM_SUBSCRIPTION_KEY_HEADER } from '@ppg/core/constants';
import { ContentService } from '@ppg/core/content';
import { LeadGenerationComponent } from '@ppg/core/models';

import { ContactUsFormApiAbstract } from '../../../domain/repository/contact-us-form-api.abstract';
import { ContactUsFormSubmitData } from '../../models/contact-us-form-submit-data.model';
import { ContactUsGetFormDataInputModel } from '../../models/contact-us-get-form-data-input.model';

@Injectable({
  providedIn: 'root',
})
export class ContactUsFormApiService implements ContactUsFormApiAbstract {
  contentService = inject(ContentService);
  #http = inject(HttpClient);
  private httpHeaders = new HttpHeaders().set(OCP_APIM_SUBSCRIPTION_KEY_HEADER, this.contentService.subscriptionKey);

  getContactUsFormData(params: ContactUsGetFormDataInputModel): Observable<LeadGenerationComponent> {
    const queryParams = new HttpParams().set('businessUnit', params.businessUnit).set('language', params.language);
    return this.#http.get<LeadGenerationComponent>(`${this.contentService.contentApiUrl}contactus`, {
      headers: this.httpHeaders,
      params: queryParams,
    });
  }

  submitContactUsFormData(formData: ContactUsFormSubmitData): Observable<HttpResponse<object>> {
    return this.#http.post(`${this.contentService.contentApiUrl}contactus`, formData, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
