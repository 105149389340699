import { ContactUsFormRepository } from '../data/repository/contact-us-form-repository';
import { ContactUsFormApiAbstract } from '../domain/repository/contact-us-form-api.abstract';
import { ContactUsFormRepositoryAbstract } from '../domain/repository/contact-us-form-repository.abstract';
import { ContactUsFormUseCases } from '../domain/use-cases/contact-us-form-use-cases';

export class ContactUsFormFactoryConfig {
  private readonly contactUsFormRepository: ContactUsFormRepositoryAbstract;

  constructor(private apiService: ContactUsFormApiAbstract) {
    this.contactUsFormRepository = new ContactUsFormRepository(apiService);
  }

  initContactUsFormUseCases(): ContactUsFormUseCases {
    return new ContactUsFormUseCases(this.contactUsFormRepository);
  }
}
