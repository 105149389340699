import { DOCUMENT } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  computed,
  inject,
  signal,
  ChangeDetectorRef,
  effect,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { catchError, EMPTY, filter, switchMap, tap } from 'rxjs';

import { NAME_PATTERN, EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from '@ppg/core/constants';
import { AppStaticRoutes, ContactUsFormDisplayOption, ResourceItem } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { LeadGenerationComponent } from '@ppg/core/models';
import { ResourceItemComponent, ResourceService } from '@ppg/shared/resource';

import { ContactUsFormDropdownComponent } from './components/contact-us-form-dropdown/contact-us-form-dropdown.component';
import { ContactUsFormInputModel } from '../../data/models/contact-us-form-input.model';
import { ContactUsFormSubmitData } from '../../data/models/contact-us-form-submit-data.model';
import { ContactUsGetFormDataInputModel } from '../../data/models/contact-us-get-form-data-input.model';
import {
  ContactUsInputValidationModel,
  ValidationPatternType,
} from '../../data/models/contact-us-input-validation.model';
import { ContactUsGtmService } from '../../data/services/contact-us-gtm/contact-us-gtm.service';
import { ContactUsSentGtmService } from '../../data/services/contact-us-gtm/contact-us-sent-gtm.service';
import { ContactUsFormUseCases } from '../../domain/use-cases/contact-us-form-use-cases';
import { ContactUsFormInputComponent } from '../contact-us-form/components/contact-us-form-input/contact-us-form-input.component';

@Component({
  selector: 'ppg-contact-us-form',
  standalone: true,
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss'],
  imports: [
    DropdownModule,
    CheckboxModule,
    ButtonModule,
    ReactiveFormsModule,
    ContactUsFormInputComponent,
    ContactUsFormDropdownComponent,
    InputTextareaModule,
    ReactiveFormsModule,
    ToastModule,
    DialogModule,
    ProgressSpinnerModule,
    MessagesModule,
    ResourceItemComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsFormComponent implements OnInit {
  private readonly contactUsFormUseCases: ContactUsFormUseCases = inject(ContactUsFormUseCases);
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly messageService: MessageService = inject(MessageService);
  private readonly languageService: LanguagesService = inject(LanguagesService);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly document = inject(DOCUMENT);
  private readonly resourceService = inject(ResourceService);
  private readonly router: Router = inject(Router);
  private readonly gtmService: ContactUsGtmService = inject(ContactUsGtmService);
  private readonly gtmServiceSent: ContactUsSentGtmService = inject(ContactUsSentGtmService);

  isLoading: boolean = true;
  isDialogVisible: boolean = false;
  showErrorsInComponent: boolean = false;
  isLoadingDialogVisible = signal<boolean>(false);

  private readonly toastKey = 'bottomCenter';
  private readonly toastSuccessSeverity = 'success';
  private readonly toastErrorSeverity = 'error';
  private readonly toastSuccessSummary = computed(() =>
    this.resourceService.getResourceByKey(ResourceItem.LEAD_GEN_SUBMIT_SUCCESS_MESSAGE),
  );
  readonly toastErrorSummary = computed(() =>
    this.resourceService.getResourceByKey(ResourceItem.LEAD_GEN_SUBMIT_ERROR_MESSAGE),
  );

  readonly resourceItem = ResourceItem;
  readonly dialogHeader = 'Submitting your information...';
  readonly dialogContent = 'This will take a few seconds.';
  readonly modal = true;
  readonly closable = true;
  isModal = true;

  isDialogModal = computed(() => this.isModal);

  formGroup = this.formBuilder.group({
    firstName: new FormControl('', {
      validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(100)],
      updateOn: 'change',
    }),
    lastName: new FormControl('', {
      validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(100)],
      updateOn: 'change',
    }),
    email: new FormControl('', {
      validators: [Validators.required, Validators.pattern(EMAIL_PATTERN), Validators.maxLength(100)],
      updateOn: 'change',
    }),
    phoneNumber: new FormControl('', {
      validators: [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN), Validators.maxLength(20)],
      updateOn: 'change',
    }),
    companyName: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(100)],
      updateOn: 'change',
    }),
    region: new FormControl('', { validators: Validators.required, updateOn: 'change' }),
    question1: new FormControl(''),
    zipcode: new FormControl(''),
    comments: [''],
    emailSubscribe: false,
  });

  public firstAndLastNameInputDatas: ContactUsFormInputModel[] = [];
  public emailAndPhoneNumberInputDatas: ContactUsFormInputModel[] = [];

  public showContactUsForm = signal(false);
  public zipCodeDisplay = signal(false);
  public question1Display = signal(false);

  public leadGeneration: Signal<LeadGenerationComponent | undefined> = computed(() =>
    this.contactUsFormUseCases.leadGenerationComponent(),
  );

  readonly ppgSubmitButtonText = computed(() =>
    this.resourceService.getResourceByKey(ResourceItem.USER_PROFILE_SUBMIT_BUTTON),
  );
  readonly titleText = computed(
    () =>
      `${this.resourceService.getResourceByKey(ResourceItem.LEAD_GEN_HEADING)} ${this.leadGeneration()?.content?.formTitle}`,
  );

  readonly routeParams: Signal<ParamMap | undefined> = toSignal(this.route.paramMap);
  readonly businessCodeName: Signal<string | null | undefined> = computed(() =>
    this.routeParams()?.get('businessCodeName'),
  );

  readonly #contactUsFormParams$ = toObservable(
    computed(() => ({
      businessUnit: this.businessCodeName(),
      language: this.languageService.language(),
    })),
  );
  readonly ContactUsFormDisplayOption = ContactUsFormDisplayOption;

  constructor() {
    this.initFormForBusinessCodeName();

    effect(() => {
      this.formGroup.controls.question1.setValidators(this.question1Display() ? [Validators.required] : []);
    });
  }

  ngOnInit(): void {
    const businessCodeName = this.businessCodeName();
    this.gtmService.sendGtmEventForBu(businessCodeName);
  }

  getFormControlByName(controlName: string): FormControl {
    const control = this.formGroup.get(controlName);
    return control instanceof FormControl ? control : new FormControl('');
  }

  checkForm(): void {
    if (this.formGroup.valid) {
      this.submitForm();
      this.showErrors(false);
    } else {
      this.showErrors(true);
    }
  }

  private showErrors(value: boolean): void {
    this.showErrorsInComponent = value;
  }

  private showToast(isSuccess: boolean): void {
    this.isLoading = true;

    const toastSeverity = isSuccess ? this.toastSuccessSeverity : this.toastErrorSeverity;
    const toastSummary = isSuccess ? this.toastSuccessSummary : this.toastErrorSummary;

    this.messageService.add({
      key: this.toastKey,
      severity: toastSeverity,
      summary: toastSummary(),
    });
  }

  private submitForm(): void {
    this.isDialogVisible = true;
    this.isLoadingDialogVisible.set(true);
    this.isModal = true;
    const submitData: ContactUsFormSubmitData = {
      firstName: this.formGroup.value.firstName ?? '',
      lastName: this.formGroup.value.lastName ?? '',
      email: this.formGroup.value.email ?? '',
      phoneNumber: this.formGroup.value.phoneNumber ?? '',
      companyName: this.formGroup.value.companyName ?? '',
      zipCode: this.formGroup.value.zipcode ?? '',
      region: this.formGroup.value.region ?? '',
      question1Answer: this.formGroup.value.question1 ?? '',
      comments: this.formGroup.value.comments ?? '',
      isEmailSubscribe: this.formGroup.value.emailSubscribe ?? false,
      sourceReferenceUrl: this.document.location.href,
      businessUnit: this.businessCodeName() ?? '',
      language: this.languageService.language() ?? '',
      userAgent: window.navigator.userAgent,
    };

    this.contactUsFormUseCases.submitContactUsFormData(submitData).subscribe({
      next: (result) => {
        this.showToast(result);
      },
      error: () => {
        this.showToast(false);
      },
      complete: () => {
        this.switchNotificationVisibility(false);
      },
    });

    const businessCodeName = this.businessCodeName();
    this.gtmServiceSent.sendGtmEventForBu(
      businessCodeName,
      this.formGroup.value.region ?? '',
      this.formGroup.value.question1 ?? '',
      this.formGroup.value.emailSubscribe ?? false,
    );

    this.isDialogVisible = false;
    this.clearForm();
  }

  private switchNotificationVisibility(isVisible: boolean): void {
    this.isDialogVisible = isVisible;
    this.isLoadingDialogVisible.set(isVisible);
  }

  private clearForm(): void {
    this.formGroup.reset();
  }

  private initFormForBusinessCodeName(): void {
    this.#contactUsFormParams$
      .pipe(
        filter((params): params is ContactUsGetFormDataInputModel => !!params.language && !!params.businessUnit),
        switchMap((params) => this.contactUsFormUseCases.getContactUsFormData(params)),
        tap((result) => {
          this.leadGeneration = computed(() => result);
          this.firstAndLastNameInputDatas = [];
          this.emailAndPhoneNumberInputDatas = [];
          this.setContactUsFormInputData(
            this.firstAndLastNameInputDatas,
            'firstName',
            this.leadGeneration()?.content.firstNameLabel,
            this.leadGeneration()?.content.firstNamePlaceholder,
            this.leadGeneration()?.content.firstNameRequiredMessage,
            this.getFirstNameValidationModels(this.leadGeneration()),
          );
          this.setContactUsFormInputData(
            this.firstAndLastNameInputDatas,
            'lastName',
            this.leadGeneration()?.content.lastNameLabel,
            this.leadGeneration()?.content.lastNamePlaceholder,
            this.leadGeneration()?.content.lastNameRequiredMessage,
            this.getLastNameValidationModels(this.leadGeneration()),
          );
          this.setContactUsFormInputData(
            this.emailAndPhoneNumberInputDatas,
            'email',
            this.leadGeneration()?.content.emailAddressLabel,
            this.leadGeneration()?.content.emailAddressPlaceholder,
            this.leadGeneration()?.content.emailAddressRequiredMessage,
            this.getEmailValidationModels(this.leadGeneration()),
          );
          this.setContactUsFormInputData(
            this.emailAndPhoneNumberInputDatas,
            'phoneNumber',
            this.leadGeneration()?.content.phoneNumberLabel,
            this.leadGeneration()?.content.phoneNumberPlaceholder,
            this.leadGeneration()?.content.phoneNumberRequiredMessage,
            this.getPhoneValidationModels(this.leadGeneration()),
          );
          this.zipCodeDisplay.set(this.getZipCodeDisplay(this.leadGeneration()));
          this.question1Display.set(this.getQuestion1Display(this.leadGeneration()));
          this.showContactUsForm.set(true);
          this.changeDetectorRef.detectChanges();
        }),
        catchError((response) => {
          if (response.status === HttpStatusCode.InternalServerError) {
            this.router.navigate([AppStaticRoutes.SERVER_ERROR]);
          } else {
            this.router.navigate([AppStaticRoutes.NOT_FOUND]);
          }
          return EMPTY;
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  private setContactUsFormInputData(
    inputData: ContactUsFormInputModel[],
    name: string | undefined,
    label: string | undefined,
    placeholder: string | undefined,
    validationErrorMessage: string | undefined,
    validationModels: ContactUsInputValidationModel[],
  ): void {
    inputData.push({
      name: name ?? '',
      label: label ?? '',
      placeholder: placeholder ?? '',
      validationErrorMessage: validationErrorMessage ?? '',
      validationModels: validationModels,
    });
  }

  private getZipCodeDisplay(leadGeneration: LeadGenerationComponent | undefined): boolean {
    return (
      leadGeneration?.content.zipcodeLabel !== undefined &&
      leadGeneration?.content.zipcodeLabel !== '' &&
      leadGeneration?.content.zipcodeLabel !== undefined &&
      leadGeneration?.content.zipcodeLabel !== undefined &&
      leadGeneration?.content.zipcodeLabel !== '' &&
      leadGeneration?.content.zipcodeLabel !== undefined
    );
  }

  private getQuestion1Display(leadGeneration: LeadGenerationComponent | undefined): boolean {
    return (
      leadGeneration?.content.question1Label !== undefined &&
      leadGeneration?.content.question1Label !== '' &&
      leadGeneration?.content.question1Tooltip !== undefined &&
      leadGeneration?.content.question1Options !== undefined &&
      leadGeneration?.content.question1RequiredMessage !== undefined &&
      leadGeneration?.content.question1Label !== undefined &&
      leadGeneration?.content.question1Label !== '' &&
      leadGeneration?.content.question1Tooltip !== undefined &&
      leadGeneration?.content.question1Options !== undefined &&
      leadGeneration?.content.question1RequiredMessage !== undefined
    );
  }

  private getFirstNameValidationModels(
    leadGeneration: LeadGenerationComponent | undefined,
  ): ContactUsInputValidationModel[] {
    return [
      { key: ValidationPatternType.Pattern, errorMessage: leadGeneration?.content.firstNameInvalidMessage ?? '' },
      { key: ValidationPatternType.MaxLength, errorMessage: leadGeneration?.content.firstNameMaxLengthMessage ?? '' },
      { key: ValidationPatternType.Required, errorMessage: leadGeneration?.content.firstNameRequiredMessage ?? '' },
    ];
  }

  private getLastNameValidationModels(
    leadGeneration: LeadGenerationComponent | undefined,
  ): ContactUsInputValidationModel[] {
    return [
      { key: ValidationPatternType.Pattern, errorMessage: leadGeneration?.content.lastNameInvalidMessage ?? '' },
      {
        key: ValidationPatternType.MaxLength,
        errorMessage: leadGeneration?.content.lastNameMaxLengthMessage ?? '',
      },
      { key: ValidationPatternType.Required, errorMessage: leadGeneration?.content.lastNameRequiredMessage ?? '' },
    ];
  }

  private getEmailValidationModels(
    leadGeneration: LeadGenerationComponent | undefined,
  ): ContactUsInputValidationModel[] {
    return [
      {
        key: ValidationPatternType.Pattern,
        errorMessage: leadGeneration?.content.emailAddressInvalidMessage ?? '',
      },
      {
        key: ValidationPatternType.MaxLength,
        errorMessage: leadGeneration?.content.emailAddressMaxLengthMessage ?? '',
      },
      {
        key: ValidationPatternType.Required,
        errorMessage: leadGeneration?.content.emailAddressRequiredMessage ?? '',
      },
    ];
  }

  private getPhoneValidationModels(
    leadGeneration: LeadGenerationComponent | undefined,
  ): ContactUsInputValidationModel[] {
    return [
      {
        key: ValidationPatternType.Pattern,
        errorMessage: leadGeneration?.content.phoneNumberInvalidMessage ?? '',
      },
      {
        key: ValidationPatternType.MaxLength,
        errorMessage: leadGeneration?.content.phoneNumberMaxLengthMessage ?? '',
      },
      {
        key: ValidationPatternType.Required,
        errorMessage: leadGeneration?.content.phoneNumberRequiredMessage ?? '',
      },
    ];
  }

  getCompanyNameValidationModels(leadGeneration: LeadGenerationComponent | undefined): ContactUsInputValidationModel[] {
    return [
      {
        key: ValidationPatternType.Required,
        errorMessage: leadGeneration?.content.companyNameRequiredMessage ?? '',
      },
      {
        key: ValidationPatternType.MaxLength,
        errorMessage: leadGeneration?.content.companyNameMaxLengthMessage ?? '',
      },
    ];
  }
}
