export enum GtmEventContactBu {
  AutomotiveOemCoatings = 'Automotive OEM Coatings',
  Refinish = 'Refinish',
  IndustrialCoatings = 'Industrial Coatings',
  PackagingCoatings = 'Packaging Coatings',
  ProtectiveAndMarineCoatings = 'Protective & Marine Coatings',
  TrafficSolutions = 'Traffic Solutions',
  SilicaProducts = 'Silica Products (Specialty Coatings & Materials)',
  OpticalMonomersAndCoatings = 'Optical Monomers and Coatings (Specialty Coatings & Materials)',
  PpgTeslin = 'PPG TESLIN® Products (Specialty Coatings & Materials)',
  VendorAndSupplier = 'Vendor & supplier',
}
