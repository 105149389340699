import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { ContactUsFormBase } from '../contact-us-form-base/contact-us-form-base';

@Component({
  selector: 'ppg-contact-us-form-label',
  standalone: true,
  templateUrl: './contact-us-form-label.component.html',
  styleUrls: ['../../contact-us-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsFormLabelComponent implements ContactUsFormBase {
  readonly inputId = input.required<string>();
  readonly labelText = input.required<string>();
}
