<div [formGroup]="formInputElement">
  <div class="dropdown-flex flex-column justify-content-start align-items-start inline-flex gap-1 w-full">
    <ppg-contact-us-form-label [inputId]="inputId()" [labelText]="labelText()"></ppg-contact-us-form-label>
    <div class="align-self-stretch flex-column justify-content-start align-items-start inline-flex gap-1">
      <p-dropdown
        [options]="inputOptions()"
        styleClass="align-self-stretch justify-content-start align-items-center inline-flex bg-white border-round-sm w-full"
        panelStyleClass="max-w-full w-full dropdown-width dropdown-border"
        [id]="inputId()"
        [formControl]="formControl()"
        placeholder="{{ inputPlaceholderText() }}">
      </p-dropdown>
      @if (formControl().invalid && showErrors()) {
        <small id="invalidFieldMessageSmall" class="validation-error-message">
          {{ invalidFieldMessage() }}
        </small>
      }
      @if (toolTip()) {
        <div class="align-self-stretch question-helper">{{ toolTip() }}</div>
      }
    </div>
  </div>
</div>
