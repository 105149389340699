import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LeadGenerationComponent } from '@ppg/core/models';

import { ContactUsFormApiAbstract } from '../../domain/repository/contact-us-form-api.abstract';
import { ContactUsFormRepositoryAbstract } from '../../domain/repository/contact-us-form-repository.abstract';
import { ContactUsFormSubmitData } from '../models/contact-us-form-submit-data.model';
import { ContactUsGetFormDataInputModel } from '../models/contact-us-get-form-data-input.model';

export class ContactUsFormRepository implements ContactUsFormRepositoryAbstract {
  constructor(private apiService: ContactUsFormApiAbstract) {}

  submitContactUsFormData(formData: ContactUsFormSubmitData): Observable<HttpResponse<object>> {
    return this.apiService.submitContactUsFormData(formData);
  }

  getContactUsFormData(params: ContactUsGetFormDataInputModel): Observable<LeadGenerationComponent> {
    return this.apiService.getContactUsFormData(params);
  }
}
