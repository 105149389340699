import { inject, Injectable } from '@angular/core';

import { CdeGtmService } from '@ppg/features/gtm';

import { GtmEventAction } from '../../enums/gtm-event-action.enum';
import { GtmEventCategory } from '../../enums/gtm-event-category.enum';
import { GtmEventContactBu } from '../../enums/gtm-event-contact-bu.enum';
import { GtmEventLabel } from '../../enums/gtm-event-label.enum';
import { GtmEvent } from '../../enums/gtm-event.enum';

@Injectable({
  providedIn: 'root',
})
export class ContactUsGtmService {
  readonly #cdeGtmService = inject(CdeGtmService);

  sendContactUsPrimaryLandingPage() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.Custom,
      eventCategory: GtmEventCategory.LeadGeneration,
      eventAction: GtmEventAction.ContactUsLanding,
      eventLabel: GtmEventLabel.ContactUsLandingPage,
    });
  }

  sendContactUsSecondaryLandingPageAc() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.Custom,
      eventCategory: GtmEventCategory.LeadGeneration,
      eventAction: GtmEventAction.ContactUsAcLanding,
      eventLabel: GtmEventLabel.ContactUsAcLandingPage,
    });
  }

  sendContactUsSecondaryLandingPageOem() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.AutomotiveOemCoatings,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.AutomotiveOemCoatings,
    });
  }

  sendContactUsSecondaryLandingPageRefinish() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.Refinish,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.Refinish,
    });
  }

  sendContactUsSecondaryLandingPageIndustrial() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.IndustrialCoatings,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.IndustrialCoatings,
    });
  }

  sendContactUsSecondaryLandingPagePackaging() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.PackagingCoatings,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.PackagingCoatings,
    });
  }

  sendContactUsSecondaryLandingPageProtective() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.ProtectiveAndMarineCoatings,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.ProtectiveAndMarineCoatings,
    });
  }

  sendContactUsSecondaryLandingPageTraffic() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.TrafficSolutions,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.TrafficSolutions,
    });
  }

  sendContactUsSecondaryLandingPageSilica() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.SilicaProducts,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.SilicaProducts,
    });
  }

  sendContactUsSecondaryLandingPageOptical() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.OpticalMonomersAndCoatings,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.OpticalMonomersAndCoatings,
    });
  }

  sendContactUsSecondaryLandingPageTeslin() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.PpgTeslin,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.PpgTeslin,
    });
  }

  sendContactUsSecondaryLandingPageVendor() {
    this.#cdeGtmService.pushTag({
      event: GtmEvent.ContactUsStart,
      contactBU: GtmEventContactBu.VendorAndSupplier,
      eventAction: GtmEventAction.ContactUsStarted,
      eventCategory: GtmEventCategory.ContactUs,
      eventLabel: GtmEventLabel.VendorAndSupplier,
    });
  }

  sendGtmEventForBu(bu: string | null | undefined): void {
    switch (bu) {
      case 'architectural-coatings':
        this.sendContactUsSecondaryLandingPageAc();
        break;
      case 'oem':
        this.sendContactUsSecondaryLandingPageOem();
        break;
      case 'refinish':
        this.sendContactUsSecondaryLandingPageRefinish();
        break;
      case 'industrial':
        this.sendContactUsSecondaryLandingPageIndustrial();
        break;
      case 'packaging':
        this.sendContactUsSecondaryLandingPagePackaging();
        break;
      case 'protective-marine':
        this.sendContactUsSecondaryLandingPageProtective();
        break;
      case 'traffic-solutions':
        this.sendContactUsSecondaryLandingPageTraffic();
        break;
      case 'silica':
        this.sendContactUsSecondaryLandingPageSilica();
        break;
      case 'optical':
        this.sendContactUsSecondaryLandingPageOptical();
        break;
      case 'teslin':
        this.sendContactUsSecondaryLandingPageTeslin();
        break;
      case 'vendor_supplier':
        this.sendContactUsSecondaryLandingPageVendor();
        break;
      default:
        this.sendContactUsPrimaryLandingPage();
    }
  }
}
