import { Route } from '@angular/router';

import { ContactUsFormComponent } from './contact-us-form.component';

export const ContactUsFormRoutes: Route[] = [
  {
    path: '',
    component: ContactUsFormComponent,
  },
];
