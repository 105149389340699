import { inject } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl } from '@angular/forms';

export class ContactUsFormControlValueAccessor implements ControlValueAccessor {
  public formBuilder = inject(FormBuilder);
  public onTouched: () => void = () => {};

  readonly formInputElement = this.formBuilder.group({
    inputElement: new FormControl(''),
  });

  writeValue(value: string): void {
    this.formInputElement.setValue({ inputElement: value }, { emitEvent: false });
  }

  public registerOnChange(fn: (value: Partial<{ inputElement: string | null }>) => void): void {
    this.formInputElement.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formInputElement.disable() : this.formInputElement.enable();
  }
}
